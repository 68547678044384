import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';

import { AppModuleNgFactory } from './app/app.module.ngfactory';

import Elpong from 'elpong';

enableProdMode();

Elpong.enableAutoload();

document.addEventListener('DOMContentLoaded', () => {
  platformBrowser().bootstrapModuleFactory(AppModuleNgFactory);
});
